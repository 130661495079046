import React, {lazy, Suspense, useState} from 'react';
// import {MonacoDiffEditor} from "react-monaco-editor";

function LazyEditorDirect(props) {
    return <div>Diff Editor is temporary disabled</div>
    // return <MonacoDiffEditor {...props} />

}

export default LazyEditorDirect
