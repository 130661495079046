import React, {useState} from 'react';
// import _ from 'underscore';
// import {
//   Link, Outlet
// } from "react-router-dom";
// import Button from 'libs/Button'


function QuestionEditor(props) {
  let {question} = props;
 //console.log('*........ ## question editor', question);


  // let v = useActionData();
  return <div>
    Страница в разрботке
    {/*<Button size={'xs'} onClick={(cb) => {*/}
    {/*  Storage.updateThemeQuestion(question, () => {*/}
    {/*    cb && cb();*/}
    {/*    props.onChange && props.onChange(question);*/}
    {/*  })*/}
    {/*}*/}
    {/*}>Save</Button>*/}
    {/*/!*{question.answer}*!/*/}
    {/*<div style={{width: '100%', display: 'block'}} className={'md-wrapper'}>*/}
    {/*<QuillEditor value={question.answer} onChange={(v) => {*/}
    {/*  question.answer = v;*/}
    {/*}*/}
    {/*}></QuillEditor>*/}
    {/*</div>*/}

  </div>
}

export default QuestionEditor
